@font-face {
  font-family: Repro;
  font-style: normal;
  font-weight: 100 300;
  src: url("../../../ABCRepro-Screen.2df6747e.otf") format("opentype");
}

@font-face {
  font-family: Repro;
  font-style: normal;
  font-weight: 400 600;
  src: url("../../../ABCRepro-Medium.a2b24394.otf") format("opentype");
}

@font-face {
  font-family: Repro;
  font-weight: 700 900;
  font-style: normal;
  src: url("../../../ABCRepro-Bold.4d7e211b.otf") format("opentype");
}

@font-face {
  font-family: Repro;
  font-style: italic;
  font-weight: 100 300;
  src: url("../../../ABCRepro-ScreenItalic.7a4b35ca.otf") format("opentype");
}

@font-face {
  font-family: Repro;
  font-style: italic;
  font-weight: 400 900;
  src: url("../../../ABCRepro-MediumItalic.52c11ddd.otf") format("opentype");
}
/*# sourceMappingURL=index.0420f9c8.css.map */
