@font-face {
    font-family: "Repro";
    font-style: normal;
    font-weight: 100 300;
    src: url("static/ABCRepro-Screen.otf") format("opentype");
}
@font-face {
    font-family: "Repro";
    font-style: normal;
    font-weight: 400 600;
    src: url("static/ABCRepro-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Repro";
    font-weight: 700 900;
    font-style: normal;
    src: url("static/ABCRepro-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Repro";
    font-style: italic;
    font-weight: 100 300;
    src: url("static/ABCRepro-ScreenItalic.otf") format("opentype");
}
@font-face {
    font-family: "Repro";
    font-style: italic;
    font-weight: 400 900;
    src: url("static/ABCRepro-MediumItalic.otf") format("opentype");
}
